const initialState = {
	user: {},
	roles: [],
	modules: [],
	isAuthenticated: false
};

export default function(state=initialState, action) {
	switch(action.type){
		case 'USER':
			return {...state, user:action.payload};
		case 'ROLES':
			return {...state, roles:action.payload};
		case 'MODULES':
			return {...state, modules: action.payload};
		case 'ISAUTHENTICATED':
			return {...state, isAuthenticated: action.payload}
		default:
			return state;
	}
}